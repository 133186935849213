<template>
  <TopNavBar />

  <!-- breadcrumb area start -->
  <div
    class="breadcrumb-area"
    style="background-image: url(assets/img/page-title-bg.png)"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="breadcrumb-inner">
            <h1 class="page-title">Data Mining</h1>
            <ul class="page-list">
              <li>
                <router-link class="nav-link" :to="{ name: 'home' }"
                  >Home</router-link
                >
              </li>
              <li>Data Mining</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- breadcrumb area End -->

  <!-- about area start -->
  <div class="about-provide-area pd-top-120 bg-none">
    <div class="container">
      <div class="row">
        <div class="col-xl-7 col-lg-6">
          <div class="img-with-video">
            <div class="img-wrap">
              <img src="assets/img/work-processing/1.png" alt="video" />
            </div>
          </div>
        </div>
        <div class="col-xl-5 col-lg-6 desktop-center-item">
          <div>
            <div class="section-title style-two">
              <h2 class="title">
                An <span>easy and affordable</span> way to access big data
              </h2>
              <p>
                Airline fares vary dynamically over time and across geographies.
                Yet, capturing them is key to understanding airline route
                performance. Keeping track manually is error-prone and not
                scaleable, while other providers may be outside your budget.
              </p>

              <p>
                So how can you harness the power of fare data to propel your
                business forward?
              </p>

              <p>
                For this purpose, we developed our own FarePlay fare retrieval
                engine, making Big Data an instant reality for your
                organisation.
              </p>
            </div>
            <div class="text-center">
              <a
                class="btn btn-radius btn-blue s-animate-3 mt-3"
                href="mailto:info@fareplay.aero"
                ><i class="fa fa-paper-plane"></i> Contact us</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row mg-top-120">
        <div class="col-xl-5 col-lg-6 offset-xl-1 desktop-center-item">
          <div>
            <div class="section-title style-two">
              <h2 class="title">
                Our <span>unique architecture</span> keeps your costs low
              </h2>

              <p>
                Our innovative setup enables fast collection of airline fares
                that are then made available to you in a way that suits you.
              </p>

              <p>
                We care about your bottom line too, which is why our
                next-generation architecture was designed from the ground up
                with efficiency in mind — so we can pass on the savings to you.
              </p>

              <p>
                We speak your language, so why not contact us to learn more?
              </p>
            </div>
            <div class="text-center">
              <a
                class="btn btn-radius btn-blue s-animate-3 mt-3"
                href="mailto:info@fareplay.aero"
                ><i class="fa fa-paper-plane"></i> Contact us</a
              >
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="img-with-video">
            <div class="img-wrap">
              <img src="assets/img/business-tool/1.png" alt="video" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- about area End -->

  <LetsTalk />
  <Footer />
</template>

<script>
import TopNavBar from "@/components/TopNavBar.vue";
import LetsTalk from "@/components/LetsTalk.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "DataMining",
  components: {
    TopNavBar,
    LetsTalk,
    Footer,
  },
};
</script>